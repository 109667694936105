import { useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import Page from 'app/layout/Page'
import { useAddEligibilityTagMutation } from 'app/redux/scribeApi'

import { TagForm, TagFormSchema } from './TagForm'

export const AddTagPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [addEligibilityTag] = useAddEligibilityTagMutation()

  const navigateBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const onSubmit = useCallback(
    (values: TagFormSchema) => {
      addEligibilityTag(values)
        .unwrap()
        .then(() => navigateBack())
    },
    [addEligibilityTag, navigateBack],
  )

  return (
    <Page>
      <TagForm formTitle={t('tags.form.add')} onCancel={navigateBack} onSubmit={onSubmit} />
    </Page>
  )
}
