import { useEffect, useState } from 'react'

import { Button, Chip, Stack, TableCell, TableRow } from '@mui/material'
import { MuiColorInput } from 'mui-color-input'

import { getTextColorForBackground } from 'app/lib/utils/helpers'
import { EligibilityTag } from 'app/models/EligibilityTag'
import { colors } from 'app/theme'

interface TagRowAction {
  label: string
  onAction: (tag: EligibilityTag) => void
}

interface TagRowProps {
  tag: EligibilityTag
  actions: TagRowAction[]
}

export const TagRow = ({ tag, actions }: TagRowProps) => {
  const [color, setColor] = useState<string>(tag.color)
  const textColor = getTextColorForBackground(color)

  useEffect(() => {
    setColor(tag.color)
  }, [tag.color])

  return (
    <TableRow key={tag.id} style={{ backgroundColor: colors.white }}>
      <TableCell sx={{ width: '30%' }}>{tag.displayName}</TableCell>
      <TableCell sx={{ whiteSpace: 'pre' }}>{tag.adminDescription}</TableCell>
      <TableCell>
        <Chip
          label={tag.shortName}
          sx={{ fontWeight: 'bold', backgroundColor: color, color: textColor }}
        />
      </TableCell>
      <TableCell>
        <MuiColorInput disabled format="hex" value={color} onChange={setColor} />
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing={1}>
          {actions.map(({ label, onAction }) => (
            <Button
              variant="outlined"
              key={label}
              data-testid={`${label} ${tag.id}`}
              onClick={() => onAction(tag)}
            >
              {label}
            </Button>
          ))}
        </Stack>
      </TableCell>
    </TableRow>
  )
}
