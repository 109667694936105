import { saveAs } from 'file-saver'

import { i18n } from 'app/i18n'
import { fetchAllEligibilityRecords } from 'app/lib/legacy-api/helpers'
import { daysInMonth, today, toISOdate, toISOdatetime } from 'app/lib/utils/date'
import {
  EligibilityDirectiveError,
  EligibilityRecordAttributes,
  EligiblePeriod,
  Organization,
  OrganizationInvoice,
  Plan,
} from 'app/models/scribe.models'

const { t } = i18n

type InvoicePlanAttributes = {
  label: string
  chargePrice: string
}
type InvoiceEligibilityRecordAttributes = EligibilityRecordAttributes & InvoicePlanAttributes

export function buildHeader(records: EligibilityRecordAttributes[] | EligibilityDirectiveError[]) {
  const attributeNames = new Set<string>()
  for (const item of records) {
    for (const attribute of Object.keys(item)) {
      attributeNames.add(attribute)
    }
  }
  return Array.from(attributeNames)
}

function convertToCsvContent(records: EligibilityRecordAttributes[]) {
  const sanitizeValue = (value = ''): string => (value.includes(',') ? `"${value}"` : value)
  const header = buildHeader(records)
  const translatedHeader = header.map((name) =>
    t(`global.csvExport.eligibilityRecordAttributes.${name}`, name),
  )

  const csv = [
    translatedHeader.join(','), // header row first
    ...records.map((record) =>
      header
        .map((columnName: string) => {
          return sanitizeValue(record[columnName])
        })
        .join(','),
    ),
  ].join('\r\n')
  return csv
}

export function saveCsv(csvContent: string, filename: string): void {
  const BOM = '\uFEFF'
  const blob = new Blob([BOM + csvContent], { type: 'data:text/csv;charset=utf-8' })
  saveAs(blob, filename)
}

export async function exportRecordsToCsv(
  token: string,
  organizationId: string,
  planIdToLabel: (planId?: number) => string | undefined,
): Promise<void> {
  const eligibilityRecordsResponses = await fetchAllEligibilityRecords(
    token,
    organizationId,
    toISOdate(today()),
  )

  const records: EligibilityRecordAttributes[] = eligibilityRecordsResponses.map((record) => {
    const activeInterval = record.eligibleIntervals.slice(-1)?.[0]

    return {
      ...record.attributes,
      activationDate: activeInterval?.startDate,
      deactivationDate: activeInterval?.endDate?.startsWith('9999') ? '' : activeInterval?.endDate,
      label: planIdToLabel(activeInterval?.planId),
    }
  })
  const csvContent = convertToCsvContent(records)
  const datetime = toISOdatetime(today())
  const filename = `export-${organizationId}-${datetime}.csv`
  saveCsv(csvContent, filename)

  return Promise.resolve()
}

const calculateAmount = (eligibleDays: number, startDate: string): string =>
  parseFloat((eligibleDays / daysInMonth(startDate)).toFixed(5)).toString()

const convertToInvoiceDetails = (
  periods: ReadonlyArray<EligiblePeriod>,
  plans: Plan[],
): InvoiceEligibilityRecordAttributes[] => {
  const plansMap = plans.reduce<Record<string, InvoicePlanAttributes>>(
    (acc, curr) => ({
      ...acc,
      [curr.id]: {
        label: curr.attributes.label,
        chargePrice: curr.attributes.chargePrice.toString(),
      },
    }),
    {},
  )

  return periods
    .map((period) => {
      const { eligibleDays, startDate, eligibilityRecord, planId } = period
      const {
        uniqueIdentifier,
        firstName,
        lastName,
        dateOfBirth,
        province,
        ...filteredAttributes
      } = eligibilityRecord.attributes
      const planAttributes = plansMap[planId]
      return {
        uniqueIdentifier,
        eligibilityRecordId: period.eligibilityRecord.id.toString(),
        firstName,
        lastName,
        dateOfBirth,
        province,
        startDate: period.startDate,
        endDate: period.endDate,
        eligibleDays: period.eligibleDays.toString(),
        ...planAttributes,
        amount: calculateAmount(eligibleDays, startDate),
        ...filteredAttributes,
      }
    })
    .sort(
      (a, b) =>
        parseInt(a.eligibilityRecordId, 10) - parseInt(b.eligibilityRecordId, 10) ||
        a.label?.localeCompare(b.label),
    )
}

export async function exportInvoiceDetailsToCsv(
  plans: Plan[],
  organizationId: Organization['id'],
  invoiceId: OrganizationInvoice['id'],
  eligibilePeriods: EligiblePeriod[],
): Promise<void> {
  const invoiceDetails = convertToInvoiceDetails(eligibilePeriods, plans)
  const csvContent = convertToCsvContent(invoiceDetails)
  const datetime = toISOdatetime(today())
  const filename = `export-invoice-details-${organizationId}-${invoiceId}-${datetime}.csv`
  saveCsv(csvContent, filename)

  return Promise.resolve()
}
