import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Card, CardContent, Stack, Typography } from '@mui/material'
import { MuiColorInput } from 'mui-color-input'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InferType, object, string } from 'yup'

import InputFormField from 'app/lib/components/form/InputFormField'

import { FormRow } from '../organization-profile/form/FormRow'

const tagFormSchema = object({
  displayName: string().required(),
  shortName: string().required(),
  color: string().required(),
  adminDescription: string().required(),
  providerDescriptionEn: string().required(),
  providerDescriptionFr: string().required(),
})

export type TagFormSchema = InferType<typeof tagFormSchema>

const defaultValues: Partial<TagFormSchema> = {
  displayName: '',
  shortName: '',
  color: '',
  adminDescription: '',
  providerDescriptionEn: '',
  providerDescriptionFr: '',
}

interface TagFormProps {
  formTitle: string
  onSubmit: (values: TagFormSchema) => void
  onCancel: () => void
  initialValues?: TagFormSchema
}

export const TagForm = ({ formTitle, onSubmit, onCancel, initialValues }: TagFormProps) => {
  const { t } = useTranslation()

  const formMethods = useForm<TagFormSchema>({
    defaultValues: { ...defaultValues, ...initialValues },
    resolver: yupResolver(tagFormSchema),
  })

  const {
    formState: { isDirty },
    handleSubmit,
    control,
  } = formMethods

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2.5} py={2}>
            <Typography variant="h2">{formTitle}</Typography>
            <Stack direction="row" justifyItems="flex-end" spacing={2}>
              <Button variant="outlined" onClick={onCancel}>
                {t('global.dialog.cancel')}
              </Button>
              <Button
                type="submit"
                key="preventDoubleSubmit"
                variant="contained"
                color="primary"
                data-testid="save-button"
                disabled={!isDirty}
              >
                {t('actions.save')}
              </Button>
            </Stack>
          </Stack>
          <Card>
            <CardContent>
              <FormRow
                label={t('tags.form.displayName')}
                field={
                  <InputFormField
                    name="displayName"
                    type="text"
                    label="Display name"
                    variant="outlined"
                    hideLabel
                  />
                }
              />
              <FormRow
                label={t('tags.form.shortName')}
                field={
                  <InputFormField
                    name="shortName"
                    type="text"
                    label="Short name"
                    variant="outlined"
                    hideLabel
                  />
                }
              />
              <FormRow
                label={t('tags.form.color')}
                field={
                  <Controller
                    control={control}
                    name="color"
                    render={({ field }) => (
                      <MuiColorInput
                        sx={{ width: '100%' }}
                        format="hex"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                }
              />
              <FormRow
                label={t('tags.form.adminDescription')}
                field={
                  <InputFormField
                    name="adminDescription"
                    type="text"
                    label="Admin description"
                    variant="outlined"
                    hideLabel
                  />
                }
              />
              <FormRow
                label={t('tags.form.providerDescriptionEn')}
                field={
                  <InputFormField
                    name="providerDescriptionEn"
                    type="text"
                    label="Provider description (EN)"
                    variant="outlined"
                    hideLabel
                  />
                }
              />
              <FormRow
                label={t('tags.form.providerDescriptionFr')}
                field={
                  <InputFormField
                    name="providerDescriptionFr"
                    type="text"
                    label="Provider description (FR)"
                    variant="outlined"
                    hideLabel
                  />
                }
              />
            </CardContent>
          </Card>
        </Stack>
      </form>
    </FormProvider>
  )
}
