import { useCallback } from 'react'

import { TableCell } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import Page from 'app/layout/Page'
import { tagsEdit } from 'app/lib/routes'
import { EligibilityTag } from 'app/models/EligibilityTag'
import { useGetEligibilityTagsQuery } from 'app/redux/scribeApi'

import { TagRow } from './TagRow'
import { TagTable } from './TagTable'

export const TAG_TABLE_HEADERS = [
  'tags.table.headers.name',
  'tags.table.headers.description',
  'tags.table.headers.shortName',
  'tags.table.headers.color',
  'tags.table.headers.action',
] as const

export const TagsPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { currentData: tags = [], isFetching } = useGetEligibilityTagsQuery()

  const navigateToEditTag = useCallback(
    (tag: EligibilityTag) => {
      navigate(tagsEdit.get(tag.id))
    },
    [navigate],
  )

  const cells = TAG_TABLE_HEADERS.map((header) => <TableCell key={header}>{t(header)}</TableCell>)

  const rows = tags.map((tag) => (
    <TagRow
      key={tag.id}
      tag={tag}
      actions={[{ label: t('tags.table.actions.edit'), onAction: navigateToEditTag }]}
    />
  ))

  return (
    <Page isLoading={isFetching}>
      <TagTable cells={cells} rows={rows} tableTitle={t('tags.pageTitle')} />
    </Page>
  )
}
