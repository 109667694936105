import React, { useCallback } from 'react'

import { Button, Stack, Table, TableBody, TableHead, TableRow, Typography } from '@mui/material'
import { t } from 'i18next'
import { useNavigate } from 'react-router'

import { NoResults } from 'app/lib/components/NoResults'
import { tagsAdd } from 'app/lib/routes'
import { NoActiveRecords } from 'assets/images'

interface TagTableProps {
  cells: React.JSX.Element[]
  rows: React.JSX.Element[]
  tableTitle?: string
}

export const TagTable = ({ cells, rows, tableTitle }: TagTableProps) => {
  const navigate = useNavigate()
  const navigateToAddTag = useCallback(() => navigate(tagsAdd.path), [navigate])

  return (
    <Stack gap={4}>
      <Stack direction="row" justifyContent="space-between" flexWrap="wrap" gap={4}>
        <Typography variant="h2" data-testid="page-title" display="flex" alignItems="top">
          {tableTitle}
        </Typography>
        <Button sx={{ alignSelf: 'end' }} variant="contained" onClick={navigateToAddTag}>
          {t('tags.buttons.add')}
        </Button>
      </Stack>
      {!!rows.length && (
        <Table style={{ boxShadow: '0px 2px 4px #F1F1F1' }} data-testid="eligibility-tags-table">
          <TableHead>
            <TableRow>{cells}</TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      )}
      {!rows.length && (
        <NoResults
          title={t('tags.noResults.title')}
          subtitle={t('tags.noResults.tryAddingOne')}
          img={<NoActiveRecords title="no_active_records" />}
        />
      )}
    </Stack>
  )
}
