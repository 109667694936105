import React, { ComponentType, useEffect } from 'react'

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { addGlobalContexts, removeGlobalContexts } from '@snowplow/browser-tracker'
import { jwtDecode } from 'jwt-decode'
import { SnackbarProvider } from 'notistack'
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom'

import { OptionalOrganizationRoute } from 'app/models/scribe.models'
import { getApplicationConfig } from 'config'

import { Layout } from './layout/Layout'
import { ScribePermission } from './lib/constants'
import { useUserPermissions } from './lib/hoc/withProtectedComponent'
import { getTenantId, useTokenData } from './lib/hooks/use-token-data'
import {
  authCheck,
  challengeLeaderboard,
  home,
  login,
  membersList,
  organizationAddAdmin,
  organizationAddMember,
  organizationAddPlan,
  organizationBilling,
  organizationChallenges,
  organizationCreate,
  organizationEAPRequest,
  organizationEdit,
  organizationEditPlan,
  organizationList,
  organizationMemberList,
  organizationMemberProfile,
  organizationMemberProfileChangePlan,
  organizationProfile,
  organizationReportingColumns,
  organizationReports,
  tagsAdd,
  tagsEdit,
  tagsList,
  userAddMember,
  userProfile,
  usersList,
} from './lib/routes'
import { getSnowplowSchema } from './lib/utils/helpers'
import { AddAdultFamilyMemberPage } from './pages/add-adult-family-member'
import { AuthCheckPage } from './pages/auth-check'
import { OrganizationMemberPage } from './pages/eligibility-record'
/* eslint-disable-line */
import { MemberChangePlan } from './pages/eligibility-record/ChangePlan'
/* eslint-disable-line */
import { EligibilityRecordSearchPage } from './pages/eligibility-record-search'
import { TagsPage } from './pages/eligibility-tag'
import { AddTagPage } from './pages/eligibility-tag/AddTag'
import { EditTagPage } from './pages/eligibility-tag/EditTag'
import { Login } from './pages/login'
import { OrganizationAddMemberPage } from './pages/organization-add-member-page'
import { AddAdminPage } from './pages/organization-admins/AddAdmin'
import { OrganizationBillingPage } from './pages/organization-billing'
import { OrganizationChallenges } from './pages/organization-challenges'
import { ChallengeLeaderboard } from './pages/organization-challenges/ChallengeLeaderboard'
import { OrganizationEAPRequestPage } from './pages/organization-eap-request'
import { OrganizationMemberListPage } from './pages/organization-eligibility-record-list'
import { OrganizationListPage } from './pages/organization-list'
import { AddPlanPage } from './pages/organization-plans/AddPlan'
import { EditPlanPage } from './pages/organization-plans/EditPlan'
import { OrganizationProfilePage } from './pages/organization-profile'
import { CreateOrganizationPage } from './pages/organization-profile/CreateOrganization'
import { EditOrganizationPage } from './pages/organization-profile/EditOrganization'
import { UpdateReportingColumns } from './pages/organization-profile/UpdateReportingColumns'
import { OrganizationReportsPage } from './pages/organization-reports'
import { PageNotFound } from './pages/page-not-found/PageNotFound'
import { UsersListPage } from './pages/user-list'
import { UserProfilePage } from './pages/user-profile'
import theme from './theme'

const config = getApplicationConfig()

export const App = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <SnackbarProvider maxSnack={3}>
      <Routes>
        {/**
         *Path of /auth-check defined in auth0-infra as an allowed callback URL for the Presto Auth0 client.
         *You must ensure that this URL is the same as in auth0-infra.
         *One more thing, DO NOT FORGET the hash in auth0 infra: /#/auth-check
         */}
        <Route path={authCheck.path} element={<AuthCheckPage />} />
        <Route path={login.path} element={<Login />} />
        <Route path={home.path} element={<ProtectedRoute component={Layout} />}>
          <Route path={organizationList.path} element={<OrganizationListPage />} />
          <Route path={membersList.path} element={<EligibilityRecordSearchPage />} />
          <Route path={organizationCreate.path} element={<CreateOrganizationPage />} />
          <Route path={organizationEdit.path} element={<EditOrganizationPage />} />
          <Route
            path={organizationReportingColumns.path}
            element={
              <RouteRequiringPermissions
                Component={UpdateReportingColumns}
                permissions={[ScribePermission.MANAGE_ORGANIZATIONS]}
                fallbackRoute={home.path}
              />
            }
          />
          <Route path={organizationReports.path} element={<OrganizationReportsPage />} />
          <Route path={organizationChallenges.path} element={<OrganizationChallenges />} />
          <Route path={challengeLeaderboard.path} element={<ChallengeLeaderboard />} />
          <Route
            path={organizationBilling.path}
            element={
              <RouteRequiringPermissions
                Component={OrganizationBillingPage}
                permissions={[ScribePermission.LIST_INVOICES]}
                fallbackRoute={home.path}
              />
            }
          />
          <Route path={organizationProfile.path} element={<OrganizationProfilePage />} />
          <Route path={organizationAddPlan.path} element={<AddPlanPage />} />
          <Route path={organizationEditPlan.path} element={<EditPlanPage />} />
          <Route path={organizationMemberList.path} element={<OrganizationMemberListPage />} />
          <Route path={organizationAddMember.path} element={<OrganizationAddMemberPage />} />
          <Route path={organizationMemberProfile.path} element={<OrganizationMemberPage />} />
          <Route path={organizationMemberProfileChangePlan.path} element={<MemberChangePlan />} />
          <Route path={organizationEAPRequest.path} element={<OrganizationEAPRequestPage />} />
          <Route path={organizationAddAdmin.path} element={<AddAdminPage />} />
          <Route path={userProfile.path} element={<UserProfilePage />} />
          <Route path={userAddMember.path} element={<AddAdultFamilyMemberPage />} />
          <Route path={home.path} element={<Navigate replace to={organizationList.path} />} />
          <Route path={usersList.path} element={<UsersListPage />} />
          <Route path={tagsList.path} element={<TagsPage />} />
          <Route path={tagsAdd.path} element={<AddTagPage />} />
          <Route path={tagsEdit.path} element={<EditTagPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </SnackbarProvider>
  </ThemeProvider>
)

// Broken into separate component because useParams causes a re-render and auth0 REALLY didn't like that.
const CaptureAnalytics: React.FC = () => {
  const { user } = useAuth0()
  const { organizationId } = useParams() as OptionalOrganizationRoute

  const token = useTokenData()

  // Set analytics
  useEffect(() => {
    let userEntity: any = undefined
    if (user) {
      userEntity = {
        schema: getSnowplowSchema('presto_user', '1-0-0'),
        data: {
          name: user.name,
          email: user.email,
        },
      }
      if (window.Sprig) window.Sprig('setEmail', user.email)
    }

    const orgEntity: any = organizationId
      ? {
          schema: getSnowplowSchema('presto_organization', '1-0-1'),
          data: {
            id: organizationId,
          },
        }
      : undefined

    const tenantId = getTenantId(token)
    const tenantEntity: Record<string, any> | undefined = tenantId
      ? {
          schema: getSnowplowSchema('tenant', '1-0-0'),
          data: {
            tenant_id: tenantId,
          },
        }
      : undefined

    addGlobalContexts([userEntity, orgEntity, tenantEntity])

    return () => {
      removeGlobalContexts([userEntity, orgEntity])
    }
  }, [user, token, organizationId])

  return <></>
}

interface ProtectedRouteProps {
  component: ComponentType
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component }) => {
  const Component = withAuthenticationRequired(component)
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()

  /**
   * Refresh the user's access token every time the page is loaded.
   * This is necessary because the External HR Admin Role might not be present in the token
   * when the user logs in.
   * Permanent solution to be determined here: https://www.notion.so/godialogue/Fix-adding-the-role-in-the-login-rule-2c54b9a341864e02a784645b9e7a123d
   */
  useEffect(() => {
    /**
     * Do not refresh for Cypress tests
     */
    if (!window.Cypress && isAuthenticated) {
      getAccessTokenSilently({ cacheMode: 'off' }).then((token) => {
        let decodedToken: { permissions?: string[] } | undefined
        try {
          decodedToken = jwtDecode(token)
        } catch {}
        // If decoded token doesn't yet contain the permissions, retry one more time.
        // see https://dialoguemd.atlassian.net/browse/DIA-44011
        if (!decodedToken?.permissions?.length) {
          getAccessTokenSilently({ cacheMode: 'off' })
        }
      })
    }
  }, [isAuthenticated, getAccessTokenSilently])

  return (
    <IntercomProvider appId={config.intercom.appId}>
      <CaptureAnalytics />
      <Component />
    </IntercomProvider>
  )
}

interface RouteRequiringPermissionsProps {
  Component: ComponentType
  permissions: ScribePermission[]
  fallbackRoute?: string
}

export const RouteRequiringPermissions: React.FC<RouteRequiringPermissionsProps> = ({
  Component,
  permissions,
  fallbackRoute,
}) => {
  const navigate = useNavigate()
  const userPermissions = useUserPermissions()
  const canViewRoute = permissions.every((p: ScribePermission) => userPermissions?.includes(p))

  if (!canViewRoute) {
    navigate(fallbackRoute || home.path)
    return
  }

  return <Component />
}
