import { useCallback } from 'react'

import { Box, TableCell } from '@mui/material'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'
import { useParams } from 'react-router'

import { EligibilityTag } from 'app/models/EligibilityTag'
import { EligibilityRecordRoute } from 'app/models/scribe.models'
import { TAG_TABLE_HEADERS } from 'app/pages/eligibility-tag'
import { TagRow } from 'app/pages/eligibility-tag/TagRow'
import { TagTable } from 'app/pages/eligibility-tag/TagTable'
import {
  useGetEligibilityTagsQuery,
  useLinkEligibilityTagToRecordMutation,
  useUnlinkEligibilityTagFromRecordMutation,
} from 'app/redux/scribeApi'

export const EligibleTags = ({ assignedTags }: { assignedTags: EligibilityTag[] }) => {
  const { data: availableTags = [] } = useGetEligibilityTagsQuery()
  const { eligibilityRecordId } = useParams() as EligibilityRecordRoute
  const [linkEligibilityTagToRecord] = useLinkEligibilityTagToRecordMutation()
  const [unlinkEligibilityTagFromRecord] = useUnlinkEligibilityTagFromRecordMutation()
  const { enqueueSnackbar } = useSnackbar()

  const isTagLinked = useCallback(
    (tag: EligibilityTag) => assignedTags.some((assignedTag) => assignedTag.id === tag.id),
    [assignedTags],
  )

  const handleLinkTag = async (tag: EligibilityTag) => {
    if (isTagLinked(tag)) {
      unlinkEligibilityTagFromRecord({
        eligibilityRecordId: Number(eligibilityRecordId),
        tagId: tag.id,
      })
        .unwrap()
        .catch((error) => {
          enqueueSnackbar(t('tags.errors.unlink'), { variant: 'error' })
          console.error('Failed to unlink tag:', error)
        })
    } else {
      linkEligibilityTagToRecord({
        tagId: tag.id,
        eligibilityRecordId: Number(eligibilityRecordId),
      })
        .unwrap()
        .catch((error) => {
          enqueueSnackbar(t('tags.errors.link'), { variant: 'error' })
          console.error('Failed to link tag:', error)
        })
    }
  }

  const cells = TAG_TABLE_HEADERS.map((header) => <TableCell key={header}>{t(header)}</TableCell>)

  const rows = availableTags.map((tag) => {
    const label = isTagLinked(tag) ? t('tags.table.actions.unlink') : t('tags.table.actions.link')
    return <TagRow key={tag.id} tag={tag} actions={[{ label, onAction: handleLinkTag }]} />
  })

  return (
    <Box py={4}>
      <TagTable tableTitle={t('eligibleTags.header')} cells={cells} rows={rows} />
    </Box>
  )
}
