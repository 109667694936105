import { useCallback, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

import Page from 'app/layout/Page'
import { tagsList } from 'app/lib/routes'
import { TagRoute } from 'app/models/scribe.models'
import { useGetEligibilityTagsQuery, useUpdateEligibilityTagMutation } from 'app/redux/scribeApi'

import { TagForm, TagFormSchema } from './TagForm'

export const EditTagPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { tagId } = useParams<TagRoute>()
  const { currentData: tags = [] } = useGetEligibilityTagsQuery()
  const [updateEligibilityTag] = useUpdateEligibilityTagMutation()

  const onSubmit = useCallback(
    (tag: TagFormSchema) => {
      updateEligibilityTag({
        id: Number(tagId),
        body: {
          ...tag,
        },
      })
        .unwrap()
        .then(() => navigate(tagsList.path))
    },
    [updateEligibilityTag, navigate, tagId],
  )

  const formInitialValues: TagFormSchema = useMemo(() => {
    const tag = tags.find(({ id }) => id === Number(tagId))

    if (!tag) {
      return {
        adminDescription: '',
        color: '',
        displayName: '',
        providerDescriptionEn: '',
        providerDescriptionFr: '',
        shortName: '',
      } satisfies TagFormSchema
    }

    return tag
  }, [tagId, tags])

  const navigateToTagsList = useCallback(() => {
    navigate(tagsList.path)
  }, [navigate])

  return (
    <Page>
      <TagForm
        formTitle={t('tags.form.edit')}
        onCancel={navigateToTagsList}
        initialValues={formInitialValues}
        onSubmit={onSubmit}
      />
    </Page>
  )
}
