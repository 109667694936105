import { FamilyMemberType } from 'app/models/scribe.models'

const home = {
  path: '/',
  get: () => '/',
}

const organizationList = {
  path: '/organizations',
  get: () => '/organizations',
}

const membersList = {
  path: '/members',
  get: () => '/members',
}

const usersList = {
  path: '/users',
  get: () => '/users',
}

const tagsList = {
  path: '/tags',
  get: () => '/tags',
}

const tagsAdd = {
  path: '/tags/add',
  get: () => '/tags/add',
}

const tagsEdit = {
  path: '/tags/:tagId/edit',
  get: (tagId: number) => `/tags/${tagId}/edit`,
}

const organizationCreate = {
  path: '/organizations/create',
  get: () => `/organizations/create`,
}

const organizationEdit = {
  path: '/organizations/:organizationId/edit',
  get: (organizationId: string) => `/organizations/${organizationId}/edit`,
}

const organizationReportingColumns = {
  path: '/organizations/:organizationId/reporting-columns',
  get: (organizationId: string) => `/organizations/${organizationId}/reporting-columns`,
}

const organizationReports = {
  path: '/organizations/:organizationId/reports',
  get: (organizationId: string) => `/organizations/${organizationId}/reports`,
}

const organizationProfile = {
  path: '/organizations/:organizationId/profile',
  get: (organizationId: string) => `/organizations/${organizationId}/profile`,
}

const organizationChallenges = {
  path: '/organizations/:organizationId/challenges',
  get: (organizationId: string) => `/organizations/${organizationId}/challenges`,
}

const challengeLeaderboard = {
  path: '/organizations/:organizationId/challenges/:challengeId/leaderboard',
  get: (organizationId: string, challengeId: string) => {
    const challenge = encodeURIComponent(challengeId)
    return `/organizations/${organizationId}/challenges/${challenge}/leaderboard`
  },
}

const organizationAddPlan = {
  path: '/organizations/:organizationId/plans/add',
  get: (organizationId: string) => `/organizations/${organizationId}/plans/add`,
}

const organizationEditPlan = {
  path: '/organizations/:organizationId/plans/:planId/edit',
  get: (organizationId: string, planId: string) =>
    `/organizations/${organizationId}/plans/${planId}/edit`,
}

const organizationBilling = {
  path: '/organizations/:organizationId/billing',
  get: (organizationId: string) => `/organizations/${organizationId}/billing`,
}

const organizationMemberList = {
  path: '/organizations/:organizationId/members',
  get: (organizationId: string) => `/organizations/${organizationId}/members`,
}

const organizationAddMember = {
  path: '/organizations/:organizationId/members/add',
  get: (organizationId: string) => `/organizations/${organizationId}/members/add`,
}

const organizationMemberProfile = {
  path: '/organizations/:organizationId/members/:eligibilityRecordId',
  get: (organizationId: string, eligibilityRecordId: string) =>
    `/organizations/${organizationId}/members/${eligibilityRecordId}`,
}

const organizationMemberProfileChangePlan = {
  path: '/organizations/:organizationId/members/:eligibilityRecordId/change-plan',
  get: (organizationId: string, eligibilityRecordId: string) =>
    `/organizations/${organizationId}/members/${eligibilityRecordId}/change-plan`,
}

const organizationEAPRequest = {
  path: '/organizations/:organizationId/requests',
  get: (organizationId: string) => `/organizations/${organizationId}/requests`,
}

const organizationAddAdmin = {
  path: '/organizations/:organizationId/admins/add',
  get: (organizationId: string) => `/organizations/${organizationId}/admins/add`,
}

const userProfile = {
  path: '/user/:userId',
  get: (userId: string) => `/user/${userId}`,
}

const userAddMember = {
  path: '/user/:userId/add-member',
  get: (userId: string, type: FamilyMemberType) => `/user/${userId}/add-member?type=${type}`,
}

const authCheck = {
  path: '/auth-check',
  get: () => '/auth-check',
}

const login = {
  path: '/login/*',
  get: () => '/login',
}

export {
  authCheck,
  challengeLeaderboard,
  home,
  login,
  membersList,
  organizationAddAdmin,
  organizationAddMember,
  organizationAddPlan,
  organizationBilling,
  organizationChallenges,
  organizationCreate,
  organizationEAPRequest,
  organizationEdit,
  organizationEditPlan,
  organizationList,
  organizationMemberList,
  organizationMemberProfile,
  organizationMemberProfileChangePlan,
  organizationProfile,
  organizationReportingColumns,
  organizationReports,
  tagsAdd,
  tagsEdit,
  tagsList,
  userAddMember,
  userProfile,
  usersList,
}
